import React from 'react';
import FullWidthImage from '../full-width-image/full-width-image';
import Styles from './grid-gallery.module.scss';

function getColForIndex(index, length) {
    switch (length) {
        case 1: {
            switch (index) {
                case 0: return 'col-12';
                default: return 'col';
            }
        }
        case 2: {
            switch (index) {
                case 0: return 'col-12 col-md-6';
                case 1: return 'col-12 col-md-6';
                default: return 'col';
            }
        }
        case 3: {
            switch (index) {
                case 0: return 'col-12';
                case 1: return 'col-12 col-md-6';
                case 2: return 'col-12 col-md-6';
                default: return 'col';
            }
        }
        case 4: {
            switch (index) {
                case 0: return 'col-12 col-md-7';
                case 1: return 'col-12 col-md-5';
                case 2: return 'col-12 col-md-5';
                case 3: return 'col-12 col-md-7';
                default: return 'col';
            }
        }
        default: {
            console.warn(`[grid-gallery] No layout is defined for length ${length}!`);
            return 'col-12'
        }
    }
}

export default props => {
    return (
        <section className={`py-5 container`}>
            <div className="row">
                {
                    props.images.map((image, index) => (
                        <div key={index} className={`${getColForIndex(index, props.images.length)} mb-4`}>
                            <FullWidthImage additionalClasses={Styles.box} customHeight="30rem" tag="div" link={image.link} fluid={image.fluid}/>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}
