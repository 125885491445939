import React from 'react';

export default props => {
    return (
        <section className={`color-white py-5 ${props.bgClass || 'bg-pattern-purpur'}`}>
            <div className="container py-3">
                <div className="row">
                    <div className="col-12 col-md-6 mb-5 mb-md-0">
                        <h1>
                            Wir beraten Sie gerne für Ihre nächste Veranstaltung auf Schloss Westerburg.
                        </h1>
                    </div>
                    <div className="col-12 col-md-5 offset-md-1 d-flex flex-column justify-content-around" style={{ minHeight: '12rem' }}>
                        <div className="position-relative d-flex align-items-center">
                            <svg className="mr-4" width="28" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.61917 17.8279C11.7992 24.0775 16.9225 29.1787 23.1721 32.3808L28.0304 27.5225C28.6267 26.9263 29.51 26.7275 30.2829 26.9925C32.7562 27.8096 35.4283 28.2512 38.1667 28.2512C39.3812 28.2512 40.375 29.245 40.375 30.4596V38.1667C40.375 39.3812 39.3812 40.375 38.1667 40.375C17.4304 40.375 0.625 23.5696 0.625 2.83333C0.625 1.61875 1.61875 0.625 2.83333 0.625H10.5625C11.7771 0.625 12.7708 1.61875 12.7708 2.83333C12.7708 5.59375 13.2125 8.24375 14.0296 10.7171C14.2725 11.49 14.0958 12.3512 13.4775 12.9696L8.61917 17.8279Z" fill="white" fillOpacity="0.9" />
                            </svg>
                            <a className="unstyled stretched-link" href="tel:+492663911401">+49 2663 911 401</a>
                        </div>
                        <div className="position-relative d-flex align-items-center">
                            <svg className="mr-4" width="28" viewBox="0 0 45 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.1665 0.833496H4.83317C2.404 0.833496 0.438587 2.821 0.438587 5.25016L0.416504 31.7502C0.416504 34.1793 2.404 36.1668 4.83317 36.1668H40.1665C42.5957 36.1668 44.5832 34.1793 44.5832 31.7502V5.25016C44.5832 2.821 42.5957 0.833496 40.1665 0.833496ZM40.1665 31.7502H4.83317V9.66683L22.4998 20.7085L40.1665 9.66683V31.7502ZM22.4998 16.2918L4.83317 5.25016H40.1665L22.4998 16.2918Z" fill="white" fillOpacity="0.9" />
                            </svg>
                            <a className="unstyled stretched-link" href="mailto:info@schlosswesterburg.de">info@schlosswesterburg.de</a>
                        </div>
                        <div className="position-relative d-flex align-items-center">
                            <svg className="mr-4" width="28" viewBox="0 0 31 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4998 0.416504C6.95359 0.416504 0.0415039 7.32859 0.0415039 15.8748C0.0415039 27.4686 15.4998 44.5832 15.4998 44.5832C15.4998 44.5832 30.9582 27.4686 30.9582 15.8748C30.9582 7.32859 24.0461 0.416504 15.4998 0.416504ZM15.4998 21.3957C12.4523 21.3957 9.979 18.9223 9.979 15.8748C9.979 12.8273 12.4523 10.354 15.4998 10.354C18.5473 10.354 21.0207 12.8273 21.0207 15.8748C21.0207 18.9223 18.5473 21.3957 15.4998 21.3957Z" fill="white" fillOpacity="0.9" />
                            </svg>
                            <a className="unstyled stretched-link" href="https://goo.gl/maps/A33DDkhj5r4Yc6pm7" target="_blank" rel="noopener noreferrer">Schloss, 56457 Westerburg</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
