import React from 'react';
import Link from '../link/link';
import Styles from './header-and-text.module.scss';

export default props => {
    let text = props.text;
    if (!Array.isArray(text)) {
        text = [ text ];
    }

    return (
        <section className={`py-5 my-2 ${props.dark ? 'bg-grey' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-5 mb-5 mb-md-0">
                        <h1 className={`${props.dark ? 'color-white' : 'color-gold'}`} dangerouslySetInnerHTML={{ __html: props.heading || '' }}></h1>
                        {
                            props.subtitle ? (
                                <span className={`${Styles.subtitle} color-grey`} dangerouslySetInnerHTML={{ __html: props.subtitle || '' }}></span>
                            ) : ''
                        }
                        {
                            props.image ? (
                                <img className={`mt-5 ${Styles.img}`} alt={props.image.alt || ''} src={props.image.src}></img>
                            ) : ''
                        }
                        {
                            props.link && props.link.to && props.link.text ? (
                                <div className="mt-4 mt-md-5">
                                    <Link className="west-button unstyled" to={props.link.to} target={props.link.target || undefined} rel={props.link.target === '_blank' ? 'noopener noreferrer' : undefined}>
                                        <span dangerouslySetInnerHTML={{ __html: props.link.text }}></span>
                                    </Link>
                                </div>
                            ) : ''
                        }
                    </div>
                    <div className={`col-12 col-md-6 offset-md-1 ${props.dark ? 'color-white' : ''}`}>
                        {
                            text.map((paragraph, index) => (
                                <p key={index} dangerouslySetInnerHTML={{ __html: paragraph || '' }}></p>
                            ))
                        }
                        
                    </div>
                </div>
            </div>
        </section>
    );
}
