import { graphql } from 'gatsby';
import * as React from 'react';
import ContactInfo from '../components/global/contact-info/contact-info';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import GridGallery from '../components/global/grid-gallery/grid-gallery';
import HeaderAndText from '../components/global/header-and-text/header-and-text';
import Layout from '../components/global/layout/layout';

export const pageQuery = graphql`
  query TagungenQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    gallery: allFile(filter: {name: {glob: "ahnensaal|hochzeit|schlosskueche-1|rittersaal-1"}}, sort: {fields: name}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            originalName
          }
        }
      }
    }
    prismicConferencePage {
      data {
        title {
          text
        }
        meta_description {
          text
        }
        title_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default props => {
  const galleryData = props.data.gallery.nodes.map(node => {
    let link;

    if (node.childImageSharp.fluid.originalName === 'schlosskueche-1.jpg') {
      link = {
        to: '/raeumlichkeiten',
        text: 'Unsere Räumlichkeiten'
      };
    }

    return {
      fluid: node.childImageSharp.fluid,
      link
    };
  })
  return (
    <Layout wrapperClassName="bg-pattern-gold"
            title={props.data.prismicConferencePage.data.title.text}
            description={props.data.prismicConferencePage.data.meta_description.text}
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <FullWidthImage tag="header" fluid={props.data.prismicConferencePage.data.title_image.localFile.childImageSharp.fluid}/>
      <HeaderAndText
        heading="Ihre Tagung auf Schloss Westerburg"
        text={[
          "Der Minnesaal eignet sich ganz besonders für Tagungen und Seminare. Die einmalig ruhige Lage des Schlosses schafft die optimalen Vorausetzungen, um sich auf das Wesentliche zu konzentrieren. Der Erholungswert in den mittelalterlichen Räumen schafft einen ausgezeichneten und speziellen Rahmen für einen regen Gedankenaustausch.",
          "Das gesamte Team von Schloss Westerburg sorgt durch einen perfekten Rundumservice für einen tadellosen Ablauf Ihres Events.",
          "Der Minnesaal sorgt durch modernstes technisches Equipment und die außerordentliche Ausstattung für das richtige Umfeld. Der erfolgreichen Durchführung Ihrer Veranstaltung wird somit nichts im Wege stehen.",
        ]}
      />
      <ContactInfo/>
      <HeaderAndText
        heading="Noch mehr Locations sind möglich"
        text={[
          `Neben unserem Minnesaal bieten unser <a class="unstyled font-weight-bold" href="/raeumlichkeiten/ahnensaal">Ahnensaal</a> und unser Außenbereich ebenfalls die Möglichkeit für Veranstaltungen verschiedener Größen.`,
          "Sprechen Sie uns gerne an, damit wir für Ihren Bedarf den richtigen Rahmen finden können."
        ]}
      />
      <GridGallery images={galleryData}/>
    </Layout>
  );
}
